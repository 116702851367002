import { useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';
import H2 from 'core/components/H2';
import PageLoader from 'core/components/Loader/PageLoader';

import { Indent } from 'site/components/Wrappers';
import BaseForm from 'site/components/BaseForm';
import BatIdContext from 'site/components/BatId';

import { VERTICAL_INDENT } from 'site/constants';
import { nameValidation, birthdateValidation } from 'site/utils/formValidations';
import { handleResponseErrors } from 'site/utils/formHelpers';

import profileQuery from 'site/queries/profile';

import EditForm from './Form';

const validate = {
  first_name: name => nameValidation('Имя', name),
  last_name: name => nameValidation('Фамилия', name),
  birthdate: birthdateValidation,
};

function Edit({ history }) {
  const [errorName, updateErrorName] = useState(null);
  const [fieldsErrors, updateFieldsErrors] = useState(null);

  const batId = useContext(BatIdContext);

  const { data: profileData, isLoading } = profileQuery(batId);

  const {
    first_name: firstName,
    last_name: lastName,
    birthdate,
  } = profileData || {};

  const initialValues = {
    first_name: firstName || '',
    last_name: lastName || '',
    birthdate: birthdate ? new Date(birthdate) : '',
  };

  const submitCallback = useCallback(values => {
    return batId.updateProfile(values)
      .then(() => history.push('/profile'))
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);
        updateErrorName(errors.error);
        updateFieldsErrors(errors.fieldsErrors);
      });
  }, [batId, history]);

  if (isLoading) return <PageLoader />;

  return (
    <>
      <H2 is='h1'>{firstName + ' ' + lastName}</H2>
      <Indent top={VERTICAL_INDENT} />

      <BaseForm
        form={EditForm}
        submitCallback={submitCallback}
        initialValues={initialValues}
        validate={validate}
        serverErrorName={errorName}
        serverFieldsErrors={fieldsErrors}
      />
    </>
  );
}

Edit.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Edit);
