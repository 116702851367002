import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

/**
 * Проверяет поле на ошибку.
 * Поле может иметь два значения при одном имени (логин - телефон или email).
 * Валидируем такие поля по разному.
 */
function getError(name, value, eventOptions, validate) {
  let error;
  if (validate[name]) {
    if (typeof validate[name] === 'function') {
      error = validate[name](value, eventOptions);
    }
    if (typeof validate[name] === 'object') {
      error = validate[name][eventOptions.validationName](value);
    }
  }
  return error;
}


function BaseForm(props) {
  const {
    initialValues,
    submitCallback,
    validate,
    form: Form,
    serverErrorStatus,
    serverErrorName,
    serverFieldsErrors,
    smsVerifyErrors,
    resetServerErrors,
    formStates,
  } = props;

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  useEffect(() => {
    // если есть ошибки валидации на сервере, определяем их в объекте errors
    if (serverFieldsErrors || smsVerifyErrors) {
      setErrors({ ...serverFieldsErrors, ...smsVerifyErrors });
    }
  }, [serverFieldsErrors, smsVerifyErrors]);

  const handleErrors = useCallback(({
    name,
    value,
    checkWasTouched, // валидируем поле, если оно уже было в фокусе (не актуально для checkbox)
    eventOptions,
  }) => {
    // удаляем ошибки полученные ранее
    const { [name]: removedError, ...rest } = errors; // eslint-disable-line

    const error = getError(name, value, eventOptions, validate);

    setErrors({
      ...rest,
      ...(error && {
        [name]: checkWasTouched
          ? touched[name] && error
          : error,
      }),
    });
  }, [errors, touched, validate]);

  const handleInputChange = useCallback((event, eventOptions = {}) => {
    const { name, value: newValue, type, checked } = event.target ? event.target : event;
    const value = type === 'checkbox' ? checked : newValue;

    // сохраняем значения полей
    setValues({
      ...values,
      [name]: value,
    });

    // поле было модифицировано
    setTouched({
      ...touched,
      [name]: true,
    });

    /**
     * Для этих контролов проверяем ошибки на onChange.
     * В других случаях проверка ошибок сработает после, в onBlur.
     */
    if (type === 'checkbox' || type === 'datepicker' || type === 'select-one') {
      handleErrors({ name, value, eventOptions });
    }
  }, [handleErrors, touched, values]);

  const handleBlur = useCallback((event, eventOptions = {}) => {
    const { name, value } = event.target;

    handleErrors({ name, value, checkWasTouched: true, eventOptions });
  }, [handleErrors]);

  const handleReset = useCallback(() => {
    setValues(initialValues);
    setErrors({});
    setTouched({});
    resetServerErrors && resetServerErrors();
  }, [initialValues, resetServerErrors]);

  const handleSubmit = useCallback((event, eventOptions = {}, setShouldShowCaptcha) => {
    event.preventDefault();

    // validate the form
    const formValidation = Object.keys(values).reduce(
      (acc, key) => {
        const newError = getError(key, values[key], eventOptions, validate);

        const newTouched = { [key]: true };
        return {
          errors: {
            ...acc.errors,
            ...(newError && { [key]: newError }),
          },
          touched: {
            ...acc.touched,
            ...newTouched,
          },
        };
      },
      {
        errors: { ...errors },
        touched: { ...touched },
      },
    );

    setErrors(formValidation.errors);
    setTouched(formValidation.touched);

    if (
      !Object.values(formValidation.errors).some(name => values[name]) && // errors object by form fields is empty
      Object.values(formValidation.touched).length ===
      Object.values(values).length && // all fields were touched
      Object.values(formValidation.touched).every(t => t === true) // every touched field is true
    ) {
      submitCallback(values, eventOptions, setShouldShowCaptcha);
    }
  }, [errors, submitCallback, touched, validate, values]);

  return (
    <Form
      {...{
        handleBlur,
        handleInputChange,
        handleSubmit,
        handleReset,
        errors,
        touched,
        values,
        serverErrorStatus,
        serverErrorName,
        formStates,
      }}
    />
  );
}

BaseForm.propTypes = {
  initialValues: PropTypes.object,
  submitCallback: PropTypes.func,
  validate: PropTypes.object,
  form: PropTypes.func,
  serverErrorStatus: PropTypes.number,
  serverErrorName: PropTypes.string,
  serverFieldsErrors: PropTypes.object,
  smsVerifyErrors: PropTypes.object,
  resetServerErrors: PropTypes.func,
  /**
   * Прочие состояния формы.
   */
  formStates: PropTypes.object,
};

const BaseFormWithHOCs = BaseForm;
BaseFormWithHOCs.displayName = 'BaseForm';

export default BaseFormWithHOCs;
export { BaseForm as StorybookComponent };
