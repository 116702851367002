import { useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import H2 from 'core/components/H2';
import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import { Indent, MaxWidthWrapper } from 'site/components/Wrappers';
import BaseForm from 'site/components/BaseForm';
import BatIdContext from 'site/components/BatId';

import { phoneValidation, emailValidation, passwordValidation } from 'site/utils/formValidations';
import { handleResponseErrors } from 'site/utils/formHelpers';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import LoginForm from 'site/pages/login/Login/Form';

import styles from './index.styl';

const validate = {
  login: {
    phone: phoneValidation,
    email: emailValidation,
  },
  password: passwordValidation,
};

const initialValues = {
  login: '',
  password: '',
};

function Login(props) {
  const {
    isMobile,
    titleComponent,
    hasContentBg,
    theme,
  } = props;

  const batId = useContext(BatIdContext);

  const { content } = theme.colors;

  const [errorStatus, updateErrorStatus] = useState(null);
  const [errorName, updateErrorName] = useState(null);
  const [fieldsErrors, updateFieldsErrors] = useState(null);

  const resetServerErrors = useCallback(() => {
    updateErrorName(null);
    updateFieldsErrors(null);
    updateErrorStatus(null);
  }, []);

  const submitCallback = useCallback((values, { byPhone, token }, setShouldShowCaptcha) => {
    if (byPhone) {
      values.login = '+' + values.login.replace(/[^\d]/g, '');
    }
    values['g-recaptcha-response'] = token;

    return batId.login({ values })
      .then(batId.loginRedirect)
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);

        updateErrorStatus(errors.status);
        updateErrorName(errors.error);
        updateFieldsErrors(errors.fieldsErrors);
        if (response.status === 429) {
          setShouldShowCaptcha(true);
        }
      });
  }, [batId]);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.hasContentBg}
          background ${content}
      `}</style>
    </scope>
  );

  return (
    <MaxWidthWrapper className={scoped.wrapClassNames(styles.login, hasContentBg && styles.hasContentBg)}>
      {titleComponent
        ? <>{titleComponent}</>
        : <Indent bottom={isMobile ? 30 : 40}><H2 is='h1'>Авторизация</H2></Indent>
      }

      <BaseForm
        form={LoginForm}
        submitCallback={submitCallback}
        initialValues={initialValues}
        validate={validate}
        serverErrorStatus={errorStatus}
        serverErrorName={errorName}
        serverFieldsErrors={fieldsErrors}
        resetServerErrors={resetServerErrors}
      />
      <scoped.styles />
    </MaxWidthWrapper>
  );
}

Login.propTypes = {
  titleComponent: PropTypes.node,
  isMobile: PropTypes.bool,
  /**
   * background в цвет theme.colors.content
   */
  hasContentBg: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(Login));
