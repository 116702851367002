import { useContext, useState, useCallback } from 'react';

import H2 from 'core/components/H2';
import H3 from 'core/components/H3';
import Link from 'core/components/Link';
import Button from 'core/components/Button';
import PageLoader from 'core/components/Loader/PageLoader';

import BaseForm from 'site/components/BaseForm';

import { passwordValidation } from 'site/utils/formValidations';
import { handleResponseErrors } from 'site/utils/formHelpers';

import BatIdContext from 'site/components/BatId';

import { Indent } from 'site/components/Wrappers';

import profileQuery from 'site/queries/profile';

import { VERTICAL_INDENT } from 'site/constants';

import Form from './Form';


const initialValues = {
  password: '',
  new_password: '',
  password_confirm: '',
};

const validate = {
  password: passwordValidation,
  new_password: passwordValidation,
  password_confirm: passwordValidation,
};


function ChangePassword() {
  const batId = useContext(BatIdContext);

  const { data: profile, isLoading } = profileQuery(batId);

  const [errorName, updateErrorName] = useState(null);
  const [fieldsErrors, updateFieldsErrors] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const submitCallback = useCallback(values => {
    return batId.changePassword(values)
      .then(() => { setIsSuccess(true); })
      .catch(({ response }) => {
        setIsSuccess(false);
        const errors = handleResponseErrors(response);

        updateErrorName(errors.error);
        updateFieldsErrors(errors.fieldsErrors);
      });
  }, [batId]);

  if (isLoading) return <PageLoader />;

  return (
    <>
      <H2 is='h1'>{profile.first_name} {profile.last_name}</H2>
      <Indent top={VERTICAL_INDENT} />
      {isSuccess ? (
        <>
          <H3>Пароль успешно изменен</H3>
          <Indent top={VERTICAL_INDENT} />
          <Link to='/'>
            <Button>На главную страницу</Button>
          </Link>
        </>
      ) : (
        <BaseForm
          form={Form}
          submitCallback={submitCallback}
          initialValues={initialValues}
          validate={validate}
          serverErrorName={errorName}
          serverFieldsErrors={fieldsErrors}
        />
      )}
    </>
  );
}

export default ChangePassword;
