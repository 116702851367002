import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'core/libs/recompose';

import withoutSSR from 'core/components/withoutSSR';
import { SimpleLoader } from 'core/components/Loader';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import Result from 'site/components/Result';
import BatIdContext from 'site/components/BatId';

import { handleResponseErrors } from 'site/utils/formHelpers';
import { setMetric } from 'site/utils';

import { METRICS } from 'site/constants';

function EmailConfirm({ location }) {
  const batId = useContext(BatIdContext);
  const [verificationResult, setVerificationResult] = useState(false);
  const [error, setError] = useState(null);
  const { verifyAccount, setSession } = batId;

  const { key } = queryString.parse(location.search);

  useEffect(() => {
    verifyAccount(key)
      .then(setSession)
      .then(() => {
        setMetric(METRICS.reg_form_registered);
        setVerificationResult(true);
      })
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);
        setError(errors.error);
        setVerificationResult(false);
      });
  }, [verifyAccount, setSession, key]);

  if (verificationResult === null) return <SimpleLoader />;

  return (
    <>
      {verificationResult
        ? <Result title='Спасибо, что подтвердили почту' />
        : <Result title='Что-то пошло не так' errorMessage={error} />}
    </>
  );
}

EmailConfirm.propTypes = {
  location: PropTypes.object,
};

export default compose(
  withoutSSR,
  withRouter,
)(EmailConfirm);
