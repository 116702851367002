import { useContext } from 'react';
import PrivateRoute from 'core/components/BaseRoutes/PrivateRoute';
import BatIdContext from 'site/components/BatId';

export default function SitePrivateRoute(props) {
  const {
    ...otherProps
  } = props;

  const batId = useContext(BatIdContext);

  return <PrivateRoute {...otherProps} user={batId} />;
}

