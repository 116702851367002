import { useContext, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'core/libs/recompose';

import { withRouter } from 'core/libs/router';

import H2 from 'core/components/H2';

import BaseForm from 'site/components/BaseForm';

import { MaxWidthWrapper } from 'site/components/Wrappers';
import BatIdContext from 'site/components/BatId';

import {
  nameValidation,
  emailValidation,
  birthdateValidation,
  passwordValidation,
  phoneValidation,
  termsAndConditionsValidation,
} from 'site/utils/formValidations';
import { handleResponseErrors } from 'site/utils/formHelpers';

import { setMetric } from 'site/utils';
import { METRICS } from 'site/constants';

import RegistrationForm from './Form';

const validate = {
  first_name: name => nameValidation('Имя', name),
  last_name: name => nameValidation('Фамилия', name),
  login: emailValidation,
  password: passwordValidation,
  password_confirm: passwordValidation,
  phone: phoneValidation,
  birthdate: birthdateValidation,
  terms_and_conditions: termsAndConditionsValidation,
};

const initialValues = {
  first_name: '',
  last_name: '',
  login: '',
  password: '',
  password_confirm: '',
  phone: '',
  birthdate: '',
  terms_and_conditions: false,
};

function Registration({ history }) {
  const batId = useContext(BatIdContext);
  const [errorName, updateErrorName] = useState(null);
  const [errorStatus, updateErrorStatus] = useState(null);
  const [fieldsErrors, updateFieldsErrors] = useState(null);

  useEffect(() => {
    if (!fieldsErrors) return;
    const phoneExist = fieldsErrors.phone && fieldsErrors.phone.indexOf('уже существует') !== -1;
    const emailExist = fieldsErrors.email && fieldsErrors.email.indexOf('уже существует') !== -1;

    if (phoneExist || emailExist) {
      setMetric(METRICS.reg_form_user_exist);
    }
  }, [fieldsErrors]);


  const submitCallback = useCallback(values => {
    values.phone = '+' + values.phone.replace(/[^\d]/g, '');

    return batId.createAccount(values)
      .then(() => history.push('/signup/phone_confirm', {
        phone: values.phone,
      }))
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);

        updateErrorStatus(errors.status);
        updateErrorName(errors.error);
        updateFieldsErrors(errors.fieldsErrors);
      });
  }, [batId, history]);

  useEffect(() => {
    const isForbidden = errorStatus === 403;
    const resendPhoneVerify = isForbidden && fieldsErrors && fieldsErrors.phone;

    if (resendPhoneVerify) setMetric(METRICS.reg_form_sms_sent);
  }, [errorStatus, fieldsErrors]);

  return (
    <MaxWidthWrapper>
      <H2 is='h1'>Регистрация</H2>

      <BaseForm
        form={RegistrationForm}
        submitCallback={submitCallback}
        initialValues={initialValues}
        validate={validate}
        serverErrorName={errorName}
        serverFieldsErrors={fieldsErrors}
        serverErrorStatus={errorStatus}
      />
    </MaxWidthWrapper>
  );
}

Registration.propTypes = {
  /** @ignore */
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default compose(
  withRouter,
)(Registration);
