import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/topics';
import { processQueryResults } from 'core/utils/query';

import { Desktop, Mobile, withBreakpoint } from 'core/components/breakpoint';
import bindProps from 'core/components/bindProps';
import Page from 'core/components/Page';
import AdWrapper from 'core/components/Ad/AdWrapper';
import PageLoader from 'core/components/Loader/PageLoader';

import { Indent } from 'site/components/Wrappers';
import TopicsGallery from 'site/components/TopicsGallery';
import TopicsGalleryBlue from 'site/components/TopicsGalleryBlue';
import { Slider } from 'site/components/Ad/mobile';
import { Billboard } from 'site/components/Ad/AdByBreakpoint';

import { INDENT, SIDE_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';

import List2 from './List2';
import List4 from './List4';

const commonApiProps = {
  sort: 'list',
  include: 'image,rubric',
};


function MainPage({ isMobile, history }) {
  const horizontal = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;

  const results = useRequest({
    queries: [
      // list1Topics
      topicsQuery({
        list: 'main_headshot_slider3',
        history,
        ...commonApiProps,
      }),
      // list2Topics
      topicsQuery({
        list: 'main_8_top',
        sort: 'list',
        limit: 8,
        include: 'image,rubric,authors',
        history,
      }),
      // list3Topics
      topicsQuery({
        list: 'main_middle_slider3',
        ...commonApiProps,
        history,
      }),
      // list4Topics
      topicsQuery({
        list: 'main_8_bottom',
        sort: 'list',
        limit: 8,
        include: 'image,rubric,authors',
        history,
      }),
      // list5Topics
      topicsQuery({
        list: 'main_bot_slider3',
        history,
        ...commonApiProps,
      }),
    ],
  });

  const {
    data: [
      list1Topics,
      list2Topics,
      list3Topics,
      list4Topics,
      list5Topics,
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  if (resultsAreLoading) {
    return <PageLoader />;
  }

  const BindedList3 = bindProps({ content: list3Topics })(TopicsGalleryBlue);

  return (
    <Page
      title='Yourchoice &mdash; новости и статьи о лайфстайле современного человека'
      description='Рассказываем о возможностях минимизировать вред от привычки и помогаем сделать осознанный выбор'
    >
      <Indent
        left={horizontal}
        right={horizontal}
        {...isMobile && { top: -20 }}
      >
        <TopicsGallery content={list1Topics} />
        <AdWrapper top={INDENT}>
          <Billboard />
        </AdWrapper>
        <Indent top={INDENT} />
        <List2 content={list2Topics} />
        <Indent top={INDENT} />
        <Desktop>
          <BindedList3 />
        </Desktop>
        <Mobile>
          <Slider
            fallback={BindedList3}
            placeholder={BindedList3}
          />
        </Mobile>
        <Indent top={INDENT} />
        <List4 content={list4Topics} />
        <Indent top={INDENT} />
        <Billboard />
        <Indent top={INDENT} />
        <TopicsGalleryBlue content={list5Topics} />
        <Indent top={INDENT} />
      </Indent>
    </Page>
  );
}

MainPage.propTypes = {
  isMobile: PropTypes.bool,
  history: PropTypes.object,
};

export default withBreakpoint(MainPage);
