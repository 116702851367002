import { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'core/libs/lodash';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Image from 'core/components/Image';
import Swipe, { Slide } from 'core/components/Swipe';

import modelPropTypes, { photoGalleryAttributes } from 'core/utils/prop-types/model';
import { scrollToElement } from 'core/utils/dom';

import ImageInfo from './ImageInfo';
import Arrows from './Arrows';
import Navigation from './Navigation';

import styles from './index.styl';

const LEFT_ARROW_KEY = 37;
const RIGHT_ARROW_KEY = 39;

const MAX_IMAGE_HEIGHT = 600;
const MAX_IMAGE_WIDTH = 640;

function getViewDimensionsState() {
  if (!(document && window)) return {};
  return {
    viewWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
    viewHeight: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
  };
}

const getGalleryAspectRatio = (images) => {
  // выбираем минимальное соотношение сторон, чтобы получить самую квадратную картинку из горизонтальных
  const imagesAspectRatios = images.map(image => {
    const {
      width,
      height,
    } = image.attributes.versions.original;
    return width / height;
  });
  const minAspectRatio = Math.min(...imagesAspectRatios);
  // если получили galleryAspectRatio < 1, значит есть вертикальные фотки - тогда делаем галерею квадратной
  return minAspectRatio > 1 ? minAspectRatio : 1;
};

function Gallery({ photoGallery, isMobile, isDesktop }) {
  const [dimensions, setDimensions] = useState({ viewHeight: 0, viewWidth: 0 });
  const [activeItem, setActiveItem] = useState(0);
  const updateWindowDimensions = debounce(() => {
    setDimensions(getViewDimensionsState());
  }, 500);
  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const heroSwipe = useRef(null);
  const containerEl = useRef(null);

  const prev = useCallback(() => {
    heroSwipe.current.prev();
  }, [heroSwipe]);

  const next = useCallback(() => {
    heroSwipe.current.next();
  }, [heroSwipe]);

  const goto = useCallback((index) => {
    heroSwipe.current.goto(index);
  }, [heroSwipe]);

  const focusGallery = useCallback(() => {
    containerEl.current.focus({
      preventScroll: true,
    });
  }, [containerEl]);

  const scrollToGallery = () => scrollToElement(containerEl.current, 1 / 3);

  const handleKeyboard = useCallback(({ keyCode }) => {
    const isLeftKey = keyCode === LEFT_ARROW_KEY;
    const isRightKey = keyCode === RIGHT_ARROW_KEY;

    if (isLeftKey || isRightKey) {
      scrollToGallery();
    }

    isLeftKey && prev();
    isRightKey && next();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onActiveIndexChange = useCallback(({ index }) => {
    setActiveItem(index);
  }, []);

  const {
    id,
    attributes: {
      images,
      image_count: imageCount,
    },
  } = photoGallery;

  const {
    attributes,
  } = images[Math.min(activeItem, imageCount - 1)];

  const {
    caption,
    credits,
    versions: {
      original: activeCover,
    },
  } = attributes || {};

  const mobileGalleryAspectRatio = isMobile && getGalleryAspectRatio(images);

  let activeImageWidth = (activeCover.width / activeCover.height) * MAX_IMAGE_HEIGHT;
  activeImageWidth = activeImageWidth > MAX_IMAGE_WIDTH ? MAX_IMAGE_WIDTH : activeImageWidth;

  let activeImageHeight = (activeCover.height * MAX_IMAGE_WIDTH) / activeCover.width;
  activeImageHeight = activeImageHeight > MAX_IMAGE_HEIGHT ? MAX_IMAGE_HEIGHT : activeImageHeight;

  return (
    <div
      key={id}
      tabIndex='0'
      className={styles.gallery}
      onKeyDown={handleKeyboard}
      onClick={scrollToGallery}
      ref={containerEl}
    >
      <div className={styles.imageContainer}>
        <Swipe
          ref={heroSwipe}
          onInteraction={focusGallery}
          onActiveIndexChange={onActiveIndexChange}
        >
          {images.map((image) => {
            const {
              id: imageId,
              attributes: {
                versions: {
                  original: cover,
                  thumbnail: previewCover,
                },
              },
            } = image;

            const imageParams = isMobile
              ? {
                aspectRatio: mobileGalleryAspectRatio,
                ...(dimensions.viewWidth > dimensions.viewHeight) && { maxHeight: dimensions.viewHeight },
              } : {
                width: activeImageWidth,
                height: activeImageHeight,
              };

            const imageComponent = (
              <Image
                placement='contain'
                {...imageParams}
                previewSrc={previewCover}
                src={cover}
              />
            );

            return (
              <Slide width='100%' key={imageId}>
                <div className={styles.image}>
                  {isDesktop ? (
                    <div className={styles.imageInnerWrapper}>
                      {imageComponent}
                    </div>
                  ) : imageComponent}
                </div>
              </Slide>
            );
          })}
        </Swipe>

        {isDesktop && (
          <Arrows
            {...{
              activeItem,
              totalCount: imageCount,
              prevHandler: prev,
              nextHandler: next,
            }}
          />
        )}
      </div>
      {isDesktop && (
        <div className={styles.navigationWrapper}>
          <Navigation
            images={images.filter((image, i) => i === activeItem + 1 || i === activeItem - 1)}
            activeItem={activeItem}
            gotoHandler={goto}
          />
        </div>
      )}

      <div {...isDesktop && { style: { width: activeImageWidth, margin: '0 auto' } }}>
        <ImageInfo
          {...{
            caption,
            credits,
            activeItem,
            totalCount: imageCount,
          }}
        />
      </div>
    </div>
  );
}

Gallery.propTypes = {
  photoGallery: modelPropTypes(photoGalleryAttributes).isRequired,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(Gallery);
