/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'core/libs/router';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { denormalizeData } from 'core/utils/api';

import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/topics';

import withTheme from 'core/components/theme';
import { withBreakpoint, Desktop } from 'core/components/breakpoint';

import H3 from 'core/components/H3';
import Image from 'core/components/Image';
import ThemeSwitch from 'core/components/ThemeSwitch';
import PageLoader from 'core/components/Loader/PageLoader';

import { MaxWidthWrapper } from 'site/components/Wrappers';
import { FieldName } from 'site/components/Texts';
import NeonButton from 'site/components/NeonButton';
import AdditionalTopics from 'site/components/AdditionalTopics';

import { CONTENT_AREA_SPEC } from 'site/constants';
import { checkisOzonSpec, checkIsPhoneSpec, sendGaEvent } from 'site/utils';

import styles from './index.styl';

function Signed({ history, location, theme, isMobile }) {
  const {
    data: content,
    isLoading,
  } = useRequest(
    topicsQuery({
      include: 'image,rubric,authors',
      limit: isMobile ? 4 : 8,
      history,
    }, {
      select: denormalizeData,
    }),
  );

  if (isLoading) return <PageLoader />;

  const isOzonSpec = checkisOzonSpec(location.pathname);
  const isPhoneSpec = checkIsPhoneSpec(location.pathname);
  const { signed } = location.state || {};
  /** Флаг для удобства тестирования страницы `/spec-registration-ozon100ftb/signed` */
  const showOnlyForTesting = location.search.indexOf('show_signed') !== -1;

  useEffect(() => {
    if (!signed) return;

    if (isOzonSpec) {
      sendGaEvent('OZON_form_1', 'already_registrated_page_ozon_form_1');
    }
    if (isPhoneSpec) {
      sendGaEvent('OZON_form_2', 'already_registrated_page_ozon_form_2');
    }
  }, [signed, isOzonSpec, isPhoneSpec]);

  if (!signed && !showOnlyForTesting) {
    if (isOzonSpec) {
      return <Redirect to='/spec-registration-ozon100ftb' />;
    }
    if (isPhoneSpec) {
      return <Redirect to='/special-reg-phone100ftb' />;
    }
  }

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.text}
          color ${theme.colors.primary}
      `}</style>
    </scope>
  );

  return (
    <>
      <style jsx>{`
        .${styles.title}
          color ${theme.colors.primary}
          font-family ${theme.fonts.display}
      `}</style>
      <MaxWidthWrapper className={styles.wrapper} maxWidth={CONTENT_AREA_SPEC}>
        <div className={styles.signed}>
          <Image
            url={require('./images/signed.png')}
            width={136}
            height={126}
          />
        </div>

        <h1 className={styles.title}>Ты уже зарегистрирован</h1>

        <FieldName className={scope.wrapClassNames(styles.text)}>
          Акция «Приз за регистрацию» доступна только для пользователей, которые регистрируются впервые.
        </FieldName>

        <div className={styles.button}>
          <NeonButton size='large' onClick={() => history.push('/')}>На главную</NeonButton>
        </div>
        <scope.styles />
      </MaxWidthWrapper>
      <Desktop>
        <ThemeSwitch name='light'>
          <div className={styles.additionalTopics}>
            <div className={styles.additionalTopicsTitle}>
              <H3>Читайте также на YourChoice</H3>
            </div>
            <AdditionalTopics content={content} type={1} />
          </div>
        </ThemeSwitch>
      </Desktop>
    </>
  );
}

Signed.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(withTheme(Signed));
