import { useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Link from 'core/components/Link';
import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Timer from 'site/components/Timer';
import BatIdContext from 'site/components/BatId';
import { ErrorMessage, LinkMedium, LinkSmall } from 'site/components/Texts';

import { handleResponseErrors } from 'site/utils/formHelpers';


function SmsResend({ phone, theme, isMobile }) {
  const batId = useContext(BatIdContext);
  const [errorStatus, setErrorStatus] = useState(null);
  const [errorMessage, setErrorName] = useState(null);
  const [sent, setSent] = useState(false);

  const submitCallback = useCallback(event => {
    event.preventDefault();

    return batId.resendPhoneTravelAskSubscription({ phone })
      .then(() => {
        setSent(true);
      })
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);

        setErrorStatus(errors.status);
        setErrorName(errors.error);
      });
  }, [batId, phone]);

  const resetTimer = () => {
    setSent(false);
    setErrorName(null);
    setErrorStatus(null);
  };

  if (errorStatus === 400) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  const TextBlock = isMobile ? LinkSmall : LinkMedium;

  if (sent) {
    const scope = resolveScopedStyles(
      <scope>
        <style jsx>{`
          .timer
            color ${theme.colors.active2}
      `}</style>
      </scope>
    );

    return (
      <TextBlock className={scope.wrapClassNames('timer')}>
        Отправить еще раз можно через <Timer resetTimer={resetTimer} />
        <scope.styles />
      </TextBlock>
    );
  }

  return (
    <Link
      type='secondary'
      to='/spec-registration-ozon100ftb'
      onClick={submitCallback}
    >
      <TextBlock>Отправить смс повторно</TextBlock>
    </Link>
  );
}

SmsResend.propTypes = {
  phone: PropTypes.string,
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withTheme(withBreakpoint(SmsResend));
