import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { compose } from 'core/libs/recompose';
import withoutSSR from 'core/components/withoutSSR';
import Button from 'core/components/Button';
import Link from 'core/components/Link';
import withTheme from 'core/components/theme';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import { MaxWidthWrapper } from 'site/components/Wrappers';

import BatIdContext from 'site/components/BatId';
import { handleResponseErrors } from 'site/utils/formHelpers';
import { setSubscriptionIsVerified } from 'site/utils/subscription';

import { setMetric } from 'site/utils';
import styles from './index.styl';

import { METRICS } from 'site/constants';

function VerifySubscription({ theme, location }) {
  const batId = useContext(BatIdContext);
  const [verificationResult, setVerificationResult] = useState(null);
  const [error, setError] = useState(null);
  const { key } = queryString.parse(location.search);

  useEffect(() => {
    batId.verifySubscription(key)
      .then(() => {
        setVerificationResult(true);
        setMetric(METRICS.mail_subscribed);
        setSubscriptionIsVerified();
      })
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);
        setError(errors.error);
        setVerificationResult(false);
      });
  }, [batId, key]);

  if (verificationResult === null) return null;

  return (
    <>
      <style jsx>{`
        .${styles.container}
          font-family ${theme.fonts.display}

        .error
          color ${theme.colors.error}
        `}</style>
      <div className={styles.container}>
        {verificationResult === false && (
          <MaxWidthWrapper>
            <div className={styles.title}>Что-то пошло не так</div>
            {error && <div className={cx(styles.subtitle, 'error')}>{error}</div>}
          </MaxWidthWrapper>
        )}
        {verificationResult === true && (
          <MaxWidthWrapper>
            <div className={styles.title}>Подписка оформлена!</div>
            <div className={styles.subtitle}>Отлично, теперь вы будете получать самые интересные материалы на почту</div>
          </MaxWidthWrapper>
        )}
        <Link to='/'>
          <Button>На главную страницу</Button>
        </Link>
      </div>
    </>
  );
}

VerifySubscription.propTypes = {
  theme: PropTypes.object,
  location: PropTypes.object,
};

export default compose(
  withoutSSR,
  withTheme,
  withRouter,
)(VerifySubscription);
