import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'core/libs/recompose';

import Link from 'core/components/Link';
import withoutSSR from 'core/components/withoutSSR';
import { SimpleLoader } from 'core/components/Loader';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import BatIdContext from 'site/components/BatId';
import Result from 'site/components/Result';

import { handleResponseErrors } from 'site/utils/formHelpers';


function LoginChangeConfirm({ location }) {
  const batId = useContext(BatIdContext);
  const { verifyLoginChange, setSession } = batId;
  const [verificationResult, setVerificationResult] = useState(null);
  const [successMessage, updateSuccessMessage] = useState(null);
  const [error, setError] = useState(null);

  const { key } = queryString.parse(location.search);

  useEffect(() => {
    verifyLoginChange(key)
      .then(response => {
        updateSuccessMessage(response.success);
        setVerificationResult(true);
        setSession(response);
      })
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);
        setError(errors.error);
        setVerificationResult(false);
      });
  }, [verifyLoginChange, setSession, key]);

  if (verificationResult === null) return <SimpleLoader />;

  return (
    <>
      {verificationResult ?
        <Result
          title={successMessage}
          subTitle={(
            <>
              Вам нужно заново <Link type='secondary' to='/login'>авторизоваться</Link>
            </>
          )}
        /> : <Result title='Что-то пошло не так' errorMessage={error} />}
    </>
  );
}

LoginChangeConfirm.propTypes = {
  location: PropTypes.object,
};

export default compose(
  withoutSSR,
  withRouter,
)(LoginChangeConfirm);
