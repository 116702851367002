import { useContext, useCallback, useState } from 'react';

import H2 from 'core/components/H2';
import { withRouter } from 'core/libs/router';
import PageLoader from 'core/components/Loader/PageLoader';


import BaseForm from 'site/components/BaseForm';
import { Indent } from 'site/components/Wrappers';
import BatIdContext from 'site/components/BatId';

import profileQuery from 'site/queries/profile';
import { emailValidation, passwordValidation } from 'site/utils/formValidations';
import { handleResponseErrors } from 'site/utils/formHelpers';

import { VERTICAL_INDENT } from 'site/constants';

import Form from './Form';
import PropTypes from 'prop-types';

const initialValues = {
  password: '',
  login: '',
};

const validate = {
  login: emailValidation,
  password: passwordValidation,
};

function LoginChange({ history }) {
  const batId = useContext(BatIdContext);

  const { data: profile, isLoading } = profileQuery(batId);

  const {
    first_name: firstName,
    last_name: lastName,
  } = profile;

  const [errorName, updateErrorName] = useState(null);
  const [fieldsErrors, updateFieldsErrors] = useState(null);

  const submitCallback = useCallback(values => {
    return batId.changeLogin(values)
      .then(batId.setSession)
      .then(() => history.push('/profile/change_email_sent'))
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);

        updateErrorName(errors.error);
        updateFieldsErrors(errors.fieldsErrors);
      });
  }, [history, batId]);

  if (isLoading) return <PageLoader />;

  return (
    <>
      <H2 is='h1'>{firstName + ' ' + lastName}</H2>
      <Indent top={VERTICAL_INDENT} />
      <BaseForm
        form={Form}
        submitCallback={submitCallback}
        initialValues={initialValues}
        validate={validate}
        serverErrorName={errorName}
        serverFieldsErrors={fieldsErrors}
      />
    </>
  );
}

LoginChange.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(LoginChange);
