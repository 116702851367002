import PropTypes from 'prop-types';
import { useContext } from 'react';

import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/topics';
import { topicQuery } from 'core/queries/topic';

import { denormalizeData } from 'core/utils/api';
import resolveRelationships from 'core/utils/relationships';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Topic from 'core/components/Topic';

import PageLoader from 'core/components/Loader/PageLoader';
import { PageIndent } from 'site/components/Wrappers';
import RegWall from 'site/components/RegWall';
import BatIdContext from 'site/components/BatId';

import TopicContent from './TopicContent';
import TopicHeader from './TopicHeader';
import TopicFooter from './TopicFooter';
import { Billboard } from 'site/components/Ad/AdByBreakpoint';


const relationships = resolveRelationships(...[
  ['rubric', 'content'],
  {},
  { rubric: {}, content: {} },
]);

function topicRenderer(topic, enhancedTopic, batId, additionalTopics, list3Topics) {
  const {
    attributes: {
      is_premium: isPremium,
    },
  } = topic;

  const lockForUnauthorized = isPremium && !batId.isAuthorized;

  return (
    <>
      <TopicHeader content={topic} />
      <TopicContent
        content={topic}
        hideContent={lockForUnauthorized}
        list3Topics={list3Topics}
      />
      {lockForUnauthorized ? <RegWall /> : <TopicFooter additionalTopics={additionalTopics} content={topic} />}
    </>
  );
}

function TopicPage(props) {
  const { isMobile, history } = props;

  const batId = useContext(BatIdContext);

  const { data: content, isTopicLoading } = useRequest(topicQuery(props));

  // list3Topics
  const {
    data: list3Topics,
    isLoading: list3TopicsAreLoading,
  } = useRequest(
    topicsQuery({
      list: 'main_middle_slider3',
      sort: 'list',
      include: 'image,rubric',
      history,
    }, {
      select: denormalizeData,
    }),
  );

  const {
    rubric: {
      slug: rubricSlug,
    },
  } = relationships(content);

  // additionalTopics
  const {
    data: additionalTopics,
    isLoading: additionalTopicsAreLoading,
  } = useRequest(
    topicsQuery({
      rubric_root: rubricSlug,
      include: 'image,rubric,authors',
      limit: isMobile ? 4 : 8,
      excluded_ids: content?.id,
      history,
    }, {
      enabled: !isTopicLoading,
      select: denormalizeData,
    }),
  );

  if (isTopicLoading || additionalTopicsAreLoading || list3TopicsAreLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <Billboard />
      <PageIndent>
        <Topic content={content}>
          {(topic, enhancedTopic) => topicRenderer(topic, enhancedTopic, batId, additionalTopics, list3Topics)}
        </Topic>
      </PageIndent>
    </>
  );
}

TopicPage.propTypes = {
  isMobile: PropTypes.bool,
  history: PropTypes.object,
};

export default withBreakpoint(TopicPage);
