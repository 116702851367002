import { useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import H2 from 'core/components/H2';

import BaseForm from 'site/components/BaseForm';

import { phoneValidation } from 'site/utils/formValidations';
import { handleResponseErrors } from 'site/utils/formHelpers';


import { MaxWidthWrapper } from 'site/components/Wrappers';
import BatIdContext from 'site/components/BatId';

import Form from './Form';

const validate = {
  phone: phoneValidation,
};

function VerifyPhoneResend({ history, location }) {
  const batId = useContext(BatIdContext);
  const { phone } = location.state || {};
  const initialValues = { phone };

  const [errorName, setErrorName] = useState(null);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);

  const submitCallback = useCallback(values => {
    values.phone = '+' + values.phone.replace(/[^\d]/g, '');

    return batId.verifyPhoneResend(values)
      .then(() => {
        history.push('/signup/phone_confirm', { phone: values.phone });
      })
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);

        setErrorStatus(errors.status);
        setErrorName(errors.error);
        setFieldsErrors(errors.fieldsErrors);
      });
  }, [batId, history]);


  return (
    <MaxWidthWrapper>
      <H2 is='h1'>Подтверждение номера телефона</H2>

      <BaseForm
        form={Form}
        submitCallback={submitCallback}
        initialValues={initialValues}
        validate={validate}
        serverErrorStatus={errorStatus}
        serverErrorName={errorName}
        serverFieldsErrors={fieldsErrors}
      />
    </MaxWidthWrapper>
  );
}

VerifyPhoneResend.propTypes = {
  /** @ignore */
  location: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(VerifyPhoneResend);
