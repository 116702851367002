import { useState, useCallback, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import ReCAPTCHA from 'react-google-recaptcha';

import Link from 'core/components/Link';
import Button from 'core/components/Button';

import Input from 'site/components/Input';
import CustomLink from 'site/components/CustomLink';

import { Indent } from 'site/components/Wrappers';
import { LinkSmall, LinkMedium, ErrorMessage } from 'site/components/Texts';

import { VERTICAL_INDENT, METRICS, RE_CAPTCHA_SITE_KEY } from 'site/constants';
import { setMetric } from 'site/utils';

import styles from './index.styl';

const handleRegBtnClick = () => {
  setMetric(METRICS.menu_reg_button_click);
};

export default function LoginForm({
  handleInputChange,
  handleBlur,
  handleSubmit,
  handleReset,
  errors,
  touched,
  values,
  serverErrorStatus,
  serverErrorName,
}) {
  const [byPhone, setByPhone] = useState(false);
  const [shouldShowCaptcha, setShouldShowCaptcha] = useState(false);
  const recaptchaRef = useRef(null);
  /**
   * TODO: есть проблема с автозаполнением полей браузером.
   * Сейчас, если залогиниться под телефоном, при следующей попытке залогиниться
   * в поле "Ваш e-mail" браузер подставит телефон.
   *
   * Разделение имен полей на name=email|phone не решает проблемы.
   *
   * не подставляет ничего
   * - autoComplete=tel|email|username
   * - name=email|phone
   *
   * подставляет последнее залогиненное значение
   * - autoComplete=login
   * - name=login
   *
   * Должен быть один input[name='login'].
   * Далее, нужно решить проблему переключения input[type='email'] на инпут с маской, полиморфно.
   */
  const loginTypeCallback = useCallback(event => {
    event.preventDefault();
    setByPhone(isByPhone => !isByPhone);
    handleReset();
  }, [handleReset]);

  const validationName = byPhone ? 'phone' : 'email';

  const loginProps = {
    name: 'login',
    error: touched.login && errors.login ? errors.login : '',
  };

  const loginForbidden = serverErrorStatus === 403;

  const onSubmit = (event) => {
    let token;
    if (shouldShowCaptcha) {
      token = recaptchaRef.current.getValue();
      recaptchaRef.current.reset();
    }
    handleSubmit(event, { byPhone, validationName, token }, setShouldShowCaptcha);
  };

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <div className={cx(styles.row, styles.field)}>
        {byPhone
          ? (
            <InputMask
              mask='+7 (999) 999-99-99'
              value={values.login}
              onChange={event => handleInputChange(event, { validationName })}
              onBlur={event => handleBlur(event, { validationName })}
            >
              <Input
                type='tel'
                label='Ваш телефон'
                {...loginProps}
              />
            </InputMask>
          ) : (
            <Input
              type='email'
              label='Ваш e-mail'
              value={values.login}
              onChange={event => handleInputChange(event, { validationName })}
              onBlur={event => handleBlur(event, { validationName })}
              {...loginProps}
            />
          )
        }
        <LinkMedium className={styles.loginType}>
          <CustomLink
            onClick={loginTypeCallback}
            to='/login'
            type='secondary'
            tabIndex={-1}
          >
            {byPhone ? 'По email' : 'Номер телефона'}
          </CustomLink>
        </LinkMedium>
      </div>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='password'
          label='Пароль'
          name='password'
          onChange={handleInputChange}
          onBlur={handleBlur}
          value={values.password}
          error={touched.password && errors.password ? errors.password : ''}
        />
      </div>

      {shouldShowCaptcha ? (
        <Indent top={VERTICAL_INDENT} bottom={VERTICAL_INDENT}>
          <ReCAPTCHA
            size='normal'
            sitekey={RE_CAPTCHA_SITE_KEY}
            ref={recaptchaRef}
          />
        </Indent>
      ) : <Indent top={VERTICAL_INDENT} />}


      <div className={cx(styles.field, styles.footer)}>
        <Button typeAttribute='submit' minWidth={117}>Войти</Button>
        <div className={styles.controls}>
          <Link
            type='secondary'
            to='/signup'
            className={styles.signup}
            onClick={handleRegBtnClick}
          >
            <LinkSmall>Зарегистрироваться</LinkSmall>
          </Link>
          <Link
            type='secondary'
            to='/login/reset_password_by_email'
          >
            <LinkSmall>Забыли пароль?</LinkSmall>
          </Link>
        </div>
      </div>
      {serverErrorName &&
        <ErrorMessage className={styles.errorMessage}>{serverErrorName}</ErrorMessage>
      }
      {loginForbidden &&
        <>
          {byPhone ? (
            <Link
              type='secondary'
              className={styles.verifyLink}
              to={{
                pathname: '/signup/verify_phone_resend',
                state: { phone: values.login },
              }}
            >
              <LinkSmall>Отправить смс повторно</LinkSmall>
            </Link>
          ) : (
            <Link
              type='secondary'
              to={{
                pathname: '/signup/verify_account_resend',
                state: { login: values.login },
              }}
              className={styles.verifyLink}
            >
              <LinkSmall>Отправить email повторно</LinkSmall>
            </Link>
          )}
        </>
      }
    </form>
  );
}

LoginForm.propTypes = {
  handleBlur: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleReset: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  serverErrorStatus: PropTypes.number,
  serverErrorName: PropTypes.string,
};
