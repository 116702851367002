import useRequest from 'core/hooks/useRequest';

import {
  authorQuery,
  topicsQuery,
} from 'core/queries/authorPage';

import AuthorPage from 'core/components/AuthorPage';

import { Indent, PageIndent } from 'site/components/Wrappers';
import Pagination from 'site/components/Pagination';
import MasonryFeed from 'site/components/MasonryFeed';
import { Billboard } from 'site/components/Ad/AdByBreakpoint';

const PaginationBlock = (props) => {
  return (
    <>
      <Billboard />
      <Indent top={40} />
      <Pagination {...props} />
    </>
  );
};

export default function Author(props) {
  const results = useRequest({ queries: [
    authorQuery(props),
    topicsQuery({
      ...props,
      limit: 8,
      include: 'rubric,image',
    }),
  ] });
  const [
    { data: author, isLoading: authorIsLoading },
    { data: topics, isLoading: topicsAreLoading },
  ] = results;

  return (
    <PageIndent>
      <AuthorPage
        author={author}
        topics={topics}
        interitemSpacing={20}
        columns={4}
        pagination={PaginationBlock}
        feedRenderer={MasonryFeed}
        isLoading={authorIsLoading || topicsAreLoading}
        grid
      />
    </PageIndent>
  );
}
