import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import {
  tagQuery,
  topicsQuery,
} from 'core/queries/tagTopics';

import { withBreakpoint } from 'core/components/breakpoint';

import TagTopics from 'core/components/TagTopics';

import { PageIndent } from 'site/components/Wrappers';
import RubricOrTagTopics from 'site/components/RubricOrTagTopics';
import { Billboard } from 'site/components/Ad/AdByBreakpoint';

import PageLoader from 'core/components/Loader/PageLoader';

import {
  RUBRICS_OR_TAGS_LIMIT,
  RUBRICS_OR_TAGS_LIMIT_MOBILE,
} from 'site/constants';

function TagPage({ location, history, match, isMobile }) {
  const limit = isMobile ? RUBRICS_OR_TAGS_LIMIT_MOBILE : RUBRICS_OR_TAGS_LIMIT;

  const [
    { data: tag, isLoading: tagIsLoading },
    { data: rawTopics, isLoading: topicsAreLoading },
  ] = useRequest({ queries: [
    tagQuery({ history, match }),
    topicsQuery({
      location,
      history,
      match,
      limit,
      include: 'image,rubric',
    }),
  ] });

  if (tagIsLoading || topicsAreLoading) return <PageLoader />;

  return (
    <>
      <Billboard />
      <PageIndent>
        <TagTopics
          tag={tag}
          topics={rawTopics}
          limit={limit}
          isLoading={topicsAreLoading}
        >
          {RubricOrTagTopics}
        </TagTopics>
      </PageIndent>
    </>
  );
}


TagPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(TagPage);
