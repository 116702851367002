import { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'core/libs/recompose';
import { withRouter } from 'core/libs/router';
import queryString from 'core/libs/query-string';

import H2 from 'core/components/H2';

import BatIdContext from 'site/components/BatId';
import { Indent, MaxWidthWrapper } from 'site/components/Wrappers';
import BaseForm from 'site/components/BaseForm';

import { handleResponseErrors } from 'site/utils/formHelpers';
import { passwordValidation } from 'site/utils/formValidations';


import { VERTICAL_INDENT } from 'site/constants';

import Form from './Form';

const validate = {
  password: passwordValidation,
  password_confirm: passwordValidation,
};

const initialValues = {
  password: '',
  password_confirm: '',
};


function ResetPassword({ history, location }) {
  const batId = useContext(BatIdContext);
  const [errorName, setErrorName] = useState(null);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const { key } = queryString.parse(location.search);

  const handleSubmit = values => {
    return batId.resetPassword(values, key)
      .then(() => {
        history.push('/login/reset_password_success');
      })
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);
        setErrorName(errors.error);
        setFieldsErrors(errors.fieldsErrors);
      });
  };

  return (
    <MaxWidthWrapper>
      <H2 is='h1'>Новый пароль</H2>
      <Indent top={VERTICAL_INDENT} />
      <BaseForm
        form={Form}
        submitCallback={handleSubmit}
        initialValues={initialValues}
        validate={validate}
        serverErrorName={errorName}
        serverFieldsErrors={fieldsErrors}
      />
    </MaxWidthWrapper>
  );
}

ResetPassword.propTypes = {
  /** @ignore */
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
};

export default compose(
  withRouter,
)(ResetPassword);
