import PropTypes from 'prop-types';

import { rawAuthorsQuery } from 'core/queries/authorsPage';
import { topicsQuery } from 'core/queries/topics';

import useRequest from 'core/hooks/useRequest';

import { withBreakpoint } from 'core/components/breakpoint';
import AuthorsPage from 'core/components/AuthorsPage';
import H1 from 'core/components/H1';
import PageLoader from 'core/components/Loader/PageLoader';

import { Indent, PageIndent } from 'site/components/Wrappers';
import { Billboard } from 'site/components/Ad/AdByBreakpoint';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import Card2 from 'site/cards/Card2';

import Boroda from './Boroda';


function Authors(props) {
  const {
    location,
    history,
    isMobile,
  } = props;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const [
    { data: rawAuthors, isLoading },
    { data: borodaTopics, isLoading: borodaTopicsIsLoading },
  ] = useRequest({ queries: [
    rawAuthorsQuery({ location, history }),
    topicsQuery({
      location,
      history,
      sort: '-views_count',
      limit: 8,
      card: Card2,
    }),
  ] });

  if (isLoading || borodaTopicsIsLoading) return <PageLoader />;

  return (
    <>
      <Billboard />
      <PageIndent>
        <H1>Авторы</H1>
        <Indent bottom={vertical} />
        <AuthorsPage rawAuthors={rawAuthors} isLoading={isLoading} />
        <Indent bottom={vertical} />
        <Boroda content={borodaTopics} />
      </PageIndent>
    </>
  );
}

Authors.propTypes = {
  isMobile: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withBreakpoint(Authors);
