import { useContext, useEffect } from 'react';
import { withRouter } from 'core/libs/router';

import PageLoader from 'core/components/Loader/PageLoader';
import H2 from 'core/components/H2';
import Link from 'core/components/Link';

import { Indent } from 'site/components/Wrappers';
import { LinkSmall, Lead } from 'site/components/Texts';
import BatIdContext from 'site/components/BatId';
import CustomLink from 'site/components/CustomLink';

import profileQuery from 'site/queries/profile';

import { VERTICAL_INDENT } from 'site/constants';

import styles from './index.styl';

function Personal() {
  const batId = useContext(BatIdContext);

  const { data: profile, isLoading, refetch } = profileQuery(batId);

  useEffect(() => {
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <PageLoader />;

  const {
    first_name: firstName,
    last_name: lastName,
    email,
    phone,
  } = profile;

  return (
    <>
      <H2 is='h1'>{firstName + ' ' + lastName}</H2>
      <Indent top={VERTICAL_INDENT} />

      <div className={styles.body}>
        <div className={styles.email}>
          <Lead>{email}</Lead>
          <LinkSmall className={styles.emailChange}>
            <CustomLink type='secondary' to='/profile/change_email'>
              Изменить e-mail
            </CustomLink>
          </LinkSmall>
        </div>
        <Indent bottom={10} />
        <Lead>{phone}</Lead>

        <Indent bottom={VERTICAL_INDENT} />
        <Link type='secondary' to='/profile/edit'>
          <LinkSmall tag='span'>Изменить данные</LinkSmall>
        </Link>
        <Indent bottom={15} />
        <Link type='secondary' to='/profile/change_password'>
          <LinkSmall tag='span'>Сменить пароль</LinkSmall>
        </Link>
      </div>
    </>
  );
}

export default withRouter(Personal);
