import { useState, useCallback, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { compose } from 'core/libs/recompose';
import { withRouter } from 'core/libs/router';
import oneLine from 'common-tags/lib/oneLine';

import pluralize from 'core/utils/pluralize';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withTheme from 'core/components/theme';

import Button from 'core/components/Button';

import Input from 'site/components/Input';
import withBatId from 'site/components/BatId/withBatId';
import Timer from 'site/components/Timer';

import { Indent } from 'site/components/Wrappers';
import { Description } from 'site/components/Texts';

import styles from './index.styl';

function getDiffInSeconds(bannedUntil) {
  const date = new Date(bannedUntil);
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  return Math.floor(Math.abs(diff / 1000));
}

function PhoneConfirmForm(props) {
  const {
    theme,
    handleSubmit,
    handleInputChange,
    handleBlur,
    handleReset,
    errors,
    touched,
    values,
  } = props;

  const {
    bannedUntil,
    attemptsCount,
  } = errors;

  const [sent, setSent] = useState();
  const [delay, setDelay] = useState();

  useEffect(() => {
    if (attemptsCount || bannedUntil) {
      setSent(true);
    }

    if (bannedUntil) {
      setDelay(() => getDiffInSeconds(bannedUntil));
    }
  }, [attemptsCount, bannedUntil]);

  const resetTimer = useCallback(() => {
    setSent(!sent);
    setDelay(0);
    handleReset();
  }, [handleReset, sent]);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.phoneConfirm}
          color ${theme.colors.primary}
      `}</style>
    </scope>
  );

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <Input
        type='hidden'
        name='phone'
        value={values.phone}
      />
      <div className={cx(styles.row, styles.codeSender)}>
        <div className={styles.field}>
          <InputMask
            mask='9999'
            value={values.key}
            onChange={handleInputChange}
            onBlur={handleBlur}
          >
            <Input
              name='key'
              type='text'
              label='Код из СМС'
              autoComplete='one-time-code'
              inputMode='numeric'
              error={touched.key && errors.key}
            />
          </InputMask>
        </div>
        <Indent left={10}>
          <Button
            disabled={delay > 0}
            typeAttribute='submit'
            size='small'
          >
            Подтвердить
          </Button>
        </Indent>
      </div>
      {sent && (
        <Description className={scope.wrapClassNames(styles.phoneConfirm)}>
          {delay > 0 && (
            <>Отправить еще раз можно через <Timer delay={delay} resetTimer={resetTimer} /></>
          )}
          {attemptsCount && (
            <>
              {oneLine`
                ${pluralize(attemptsCount, ['Осталась', 'Осталось', 'Осталось'])}
                ${attemptsCount}
                ${pluralize(attemptsCount, ['попытка', 'попытки', 'попыток'])}
              `}
            </>
          )}
        </Description>
      )}
      <scope.styles />
    </form>
  );
}

PhoneConfirmForm.propTypes = {
  theme: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  handleReset: PropTypes.func,
  handleInputChange: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  serverErrorName: PropTypes.string,
};

export default compose(
  withBatId,
  withRouter,
  withTheme
)(PhoneConfirmForm);
